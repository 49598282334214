:root {
  --cell-size: 70px;
  --primary-color: #6343d8;
  --secondary-color: #f9f9f9;
  --accent-color: #e6f7ff;
  --success-color: #28a745;
  --text-color: #333;
  --text-muted: #777;
}

.g-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2%;
  height: 100vh;
  text-align: center;
  overflow-y: auto;
}

.grid-container {
  display: grid;
  grid-gap: 0;
  justify-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  margin-bottom: 20px;
}

.grid-row {
  display: contents;
}

.grid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--cell-size);
  height: var(--cell-size);
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  user-select: none;
}

.grid-cell:hover {
  background-color: var(--accent-color);
  transform: scale(1.1);
}

.word-list {
  margin-top: 10px;
  text-align: center;
}

.word-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.word-list li {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.word-list li.crossed-out {
  text-decoration: line-through;
  color: var(--text-muted);
}

.discovered-word-section {
  margin-top: 10px;
  padding: 15px;
  background: var(--secondary-color);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  text-align: center;
  width: 90%;
  max-width: 600px;
}

.discovered-word-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.discovered-word {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--primary-color);
  margin: 0;
}

.discovered-definition {
  font-size: 1rem;
  color: var(--text-color);
  padding: 10px;
  background-color: var(--accent-color);
  border-radius: 5px;
}

.success-message {
  margin-top: 15px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--success-color);
}

.next-puzzle-button {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.next-puzzle-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.go-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
  font-size: 2rem;
}

.go-back-button:hover {
  color: #b30000;
  transform: scale(1.2);
}

.audio-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

.audio-button:hover {
  color: #0056b3;
}

/* Card Styling */
.kanban-card {
  transition: transform 0.3s ease-in-out;
}

/* Draggable Name Styling */
.draggable-name {
  cursor: grab;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.draggable-name.dragging {
  opacity: 0.5;
  transform: scale(1.05);
}

.draggable-name:active {
  cursor: grabbing;
}
.discovered-word-header {
  display: flex;
  align-items: center;
}
/* Responsive Design */
@media (max-width: 768px) {
  :root {
    --cell-size: 50px;
  }

  .grid-cell {
    font-size: 1.2rem;
  }

  .discovered-word {
    font-size: 1.2rem;
  }

  .discovered-definition {
    font-size: 0.9rem;
  }

  .next-puzzle-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .success-message {
    font-size: 1.2rem;
  }

  .go-back-button {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  :root {
    --cell-size: 40px;
  }

  .grid-cell {
    font-size: 1rem;
  }

  .discovered-word-section {
    width: 100%;
  }

  .next-puzzle-button {
    font-size: 0.8rem;
  }
}
